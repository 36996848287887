import gql from 'graphql-tag';
//import { GET_CART_ITEMS } from './pages/cart';

export const schema = gql`
  extend type Launch {
    isInCart: Boolean!
  }
`;

export const typeDefs = gql`
  extend type Query {
    isLoggedIn: Boolean!
    cartItems: [Launch]!
  }

  extend type Launch {
    isInCart: Boolean!
  }

  extend type Mutation {
    addOrRemoveFromCart(id: ID!): [Launch]
  }
`;

export const resolvers = {
    Launch: {
      // isInCart: (launch, _, { cache }) => {
      //   const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });
      //   return cartItems.includes(launch.id);
      // },
    },
    Mutation: {
    addOrRemoveFromCart: (_, { id }, { cache }) => {
        // console.log(id);
        // const { cartItems } = cache.readQuery({ query: GET_CART_ITEMS });
        // const data = {
        //   cartItems: cartItems.includes(id)
        //     ? cartItems.filter(i => i !== id)
        //     : [...cartItems, id],
        // };
        // cache.writeQuery({ query: GET_CART_ITEMS, data });
        // return data.cartItems;
      },
    }
  };