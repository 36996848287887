import React, { Component } from 'react';



  export default class StartPage extends Component { 
    
    render() {
        return (
            <div> Hello StartPage
                
            </div>
        )
    }
};