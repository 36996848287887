import React, { Component } from 'react';
import Dispatcher from './DslEditorDispatcher';
import { Container, Section, Bar } from 'react-simple-resizer';
import {
    Toolbar,
    ToolbarRow,
    ToolbarSection,
    ToolbarTitle,
    ToolbarMenuIcon,
    ToolbarIcon
  } from '@rmwc/toolbar';
  import '@material/toolbar/dist/mdc.toolbar.css';

interface RepositoryPageProps {
}
interface RepositoryPageState {
    collapseAll?: boolean;
    selectedItem?: any;
}

export default class RepositoryPage extends Component<RepositoryPageProps,RepositoryPageState> { 
    
    render() {
        return (
            <Container style={{ minHeight: "calc(100vh - 64px)" }}>
                <Section minSize={300}>
                    <Toolbar style={ { background: "#F5F5F5", color: "black", zIndex: 0, marginBottom: "10px" } }>
                        <ToolbarRow style = {{minHeight: "0px"}}>
                            <ToolbarSection alignStart style = {{ padding: "0px 0px 0px 12px" }}>
                            <ToolbarMenuIcon style = {{cursor: "default"}} icon="library_books"/>
                            <ToolbarTitle style = {{fontSize: "1em"}}>Repository</ToolbarTitle>
                            </ToolbarSection>
                            <ToolbarSection alignEnd alignStart style = {{ padding: "0px 12px 0px 0px" }}>
                            <ToolbarIcon icon="add" style={{ color: 'black' }}/>
                            <ToolbarIcon icon="filter_none" style={{ color: 'black' }} onClick={() => this.setState({ collapseAll: true})} />
                            </ToolbarSection>
                        </ToolbarRow>
                    </Toolbar>
                    {/* <TreeView collapseAll={this.state && !!this.state.collapseAll } onItemSelected={(item: any) => this.setState({ selectedItem: item}) } /> */}
                </Section>
                <Bar size={2} style={{ background: '#F5F5F5', cursor: 'col-resize' }} />
                <Section minSize={100} >
                    <Dispatcher item={ this.state && this.state.selectedItem } />
                </Section>
            </Container>
            
        )
    }

};