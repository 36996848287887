import React, { Component } from 'react';
import { ApolloConsumer } from 'react-apollo';
import {
    TopAppBar,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarNavigationIcon,
    TopAppBarActionItem,
    TopAppBarTitle,
    TopAppBarFixedAdjust
  } from '@rmwc/top-app-bar';
import '@material/top-app-bar/dist/mdc.top-app-bar.css';
import TopMenu from './TopMenu'


  interface MyTopAppBarProps {
  }
  interface MyTopAppBarState {
    menuOpen: boolean;
  }

  export default class MyTopAppBar extends Component<MyTopAppBarProps,MyTopAppBarState> { 
    
    constructor(props: MyTopAppBarProps) {
        super(props);
        this.state = { menuOpen: false };
    }
      

    render() {
        return (
              
            <ApolloConsumer>
                {client => (
                    <div style={ { overflow: "hidden"  } } >
                    <TopAppBar>
                        <TopAppBarRow>
                            <TopAppBarSection alignStart>
                            <TopAppBarNavigationIcon icon="menu" onClick={ () => this.setState({menuOpen: this.state.menuOpen === undefined ? false : !this.state.menuOpen})}  />
                            <TopAppBarTitle>My DSL</TopAppBarTitle>
                            </TopAppBarSection>
                            <TopAppBarSection alignEnd>
                                <TopAppBarNavigationIcon icon="exit_to_app" onClick={ () => {client.writeData({ data: { isLoggedIn: false } });  localStorage.clear() }}  />
                            </TopAppBarSection>
                        </TopAppBarRow>
                    </TopAppBar>
                    <TopAppBarFixedAdjust /> 
                
                    <div style={ {minHeight: '100%', position: 'fixed', zIndex: 1} }>
                        <TopMenu  opened={this.state.menuOpen} onClose={ () => this.setState( { menuOpen: false})} />
                    
                    </div>
                </div>
                 )}
            </ApolloConsumer>
            
        )
    }
};

