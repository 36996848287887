import React from 'react';
import { Mutation, ApolloConsumer } from 'react-apollo';
import gql from 'graphql-tag';

import { LoginForm, BackendServer, Loading } from '../components';

const LOGIN_USER = gql`
  mutation login($userId: String!) {
    login(nickname: $userId)
  }
`;
interface LoginMutationArgs {
  userId:string;
}
interface LoginMutationResult {
  login:string;
}

export default function Login(props: {servers: BackendServer[], setUri: (uri: string) => void}) {
  console.log("Login.Render" );
    return (
      <ApolloConsumer>
        {client => (
          <Mutation<LoginMutationResult,LoginMutationArgs>
            mutation={LOGIN_USER}
            onCompleted={({ login }) => {
              localStorage.setItem('token', login);
              client.writeData({ data: { isLoggedIn: true } });
            }}
          >
            {(login, { loading, error }) => {
              // this loading state will probably never show, but it's helpful to
              // have for testing
              // if (loading) {
              //   console.log("Loading...");
              //   return <Loading />;
                
              // }
              if (error) {
                  console.log("Login:", error );
                  // return <p>An error occurred</p>;
              }
              return <LoginForm servers={props.servers} loading={loading} error={error && error.message} login={(userId, server) => { server && props.setUri(server); login({ variables: { userId: userId } });}} />;
            }}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  }