import React, { Component } from 'react';
import gql from 'graphql-tag';
import { ApolloConsumer } from 'react-apollo';

import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab/dist/mdc.tab.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/toolbar/dist/mdc.toolbar.css';
import '@material/fab/dist/mdc.fab.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/snackbar/dist/mdc.snackbar.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/button/dist/mdc.button.css';

import { GraphStyling, GraphProvider, GraphEditor, GraphSidebar, GraphSidebarPanel, GraphStencil, DomainModel, GraphPropertyEditor, GraphToolbar, GraphDiagram, MxMenubar, MxToolbar,
	DismissibleSidebar, SidebarSection, SidebarItem, Toolbar, ToolbarRow, ToolbarSection, ToolbarButton, ToolbarLabel, ResizableSection, ThemeColorBG, TreeView, Modeling, useWorkbench, IWorkbenchContext } from 'my-dsl-core'
import {ClassInfo,PropertyInfo,RelationInfo} from '../designer/DomainModel';
 

import { Container, Section, Bar } from 'react-simple-resizer';
import { shallowEqualExplain } from 'shallow-equal-explain';


// import './styles/grapheditor.css';
// import './styles/common.css';
//import './GraphPage.css';
import expandedIcon from '../resources/images/menu-up.svg';
import collapsedIcon from '../resources/images/menu-down.svg';
// import resizeIcon from './images/resize.gif';
// import minimizeIcon from './images/minimize.gif';
// import normalizeIcon from './images/normalize.gif';
// import closeIcon from './images/close.gif';
// import txtRes from './resources/grapheditor.txt';
// import stylesRes from './styles/default.xml'; 
// import graphData from './resources/myDsl.graph.xml'; 
// import modelData from './resources/myDsl.model'; 


import tableIcon from '../resources/images/class.svg';
import columnIcon from '../resources/images/property.svg';
import { IModelDesignerService } from 'my-dsl-core';

// import './App.css';

const {EdgeStyleNames, HorizontalAlignment, VerticalAlignment, BuiltInShapes} = GraphStyling;

interface GraphPageState {
	collapseAll?: boolean;
	selectedItems?: any[];
}

@useWorkbench
class Page extends Component<{},GraphPageState> { 

	editorRef = React.createRef<GraphEditor>();
	editorUi: any;
	mx: any;
	designerService?: IModelDesignerService;
	context!: IWorkbenchContext

  componentDidUpdate(prevProps: any) {
    const currentProps = this.props;
    const shallowEqualExplanation = shallowEqualExplain(
        prevProps,
        currentProps,
    );

    console.log("GraphPage.componentDidUpdate", { prevProps, currentProps, shallowEqualExplanation });
	}

	// shouldComponentUpdate() {
	// 	return false;
	// }

  constructor(props: any) {
		super(props);
		
		console.log("GraphPage");
		this.state = {};

		// this.domainModel = new DomainModel([new ClassInfo(), new PropertyInfo(), new RelationInfo()],
		// 	{
		// 		defaultVertexStyle: {
		// 			shape: BuiltInShapes.rectangle,
		// 			align: HorizontalAlignment.left,
		// 			// verticalAlign: VerticalAlignment.middle,
		// 			// verticalLabelPosition: VerticalAlignment.middle,
		// 			spacingLeft: 4,
					
		// 		} as GraphStyling.VertexStyle,
		// 		defaultEdgeStyle: {
		// 			edgeStyle: EdgeStyleNames.EntityRelation,
		// 			labelBackgroundColor: '#FFFFFF',
		// 			strokeColor: 'var(--mdc-theme-primary, #6200ee)',
		// 			strokeWidth: 2,
		// 			rounded: true,
		// 		} as GraphStyling.EdgeStyle,
		// 	}
		// );
		//this.domainModel.addItemSelectedListener(this.onDomainModelItemSelected.bind(this));
		this.state = {};
		//this.onInit.bind(this);
  }

  onInit(editorUi: any, mx: any, designerService: IModelDesignerService) {
		const {mxClient, mxGraph, mxUtils, mxConstants, mxImage, mxConnectionHandler, mxCellRenderer, mxDivResizer, mxPoint, mxStackLayout, mxGraphModel, mxEvent, mxCell, mxResources, mxWindow, mxOutline, mxEffects} = mx;
		
		// mxWindow.prototype.resizeImage = resizeIcon;
		// mxWindow.prototype.minimizeImage = minimizeIcon;
		// mxWindow.prototype.closeImage = closeIcon;
		// mxWindow.prototype.normalizeImage = normalizeIcon;
		
		this.editorUi = editorUi;
		this.mx = mx;
		this.designerService = designerService;
		 //editorUi.loadModel(graphData, modelData);
		
	}

	onItemSelected(item: any) {
		console.log("GraphPage.onItemSelected", item);
		//this.setState({ selectedItem: item});
		//const {mx, editorUi} = this;
		if (item.document && this.editorRef.current) {

			this.designerService!.loadDocument(item.document);
			// designer.loadDocument(item.document);
			// //this.setState({selectedItems: items});
			// const promise = new Promise(function(resolve, reject) {
			// 	// executor (the producing code, "singer")
		
			// 	const urls: string[] = [];
			// 	if (item.DomainModelView && !item.DomainModelView.graphXml) urls.push(item.DomainModelView.url);
			// 	if (item.DomainModel && !item.DomainModel.vertices) urls.push(item.DomainModel.url);
				
			// 	if (urls.length > 0) {
			// 			mx.mxUtils.getAll(urls, (data: any) => {
			// 			try
			// 			{
			// 				item.DomainModelView.graphXml = mx.mxUtils.parseXml(data[0].getText());
			// 				item.DomainModel.vertices = JSON.parse(data[1].getText());
							
			// 				//this.editor.graph.setSelectionCells(editor.graph.importGraphModel(doc.documentElement));
			// 				resolve();
			// 			}
			// 			catch (e)
			// 			{
			// 				reject(e);
			// 				//mx.mxUtils.alert(mx.mxResources.get('invalidOrMissingFile') + ': ' + (e.message ? e.message : e));
			// 			}
			// 		});
			// 	} else resolve();
				
			// });
			// promise.then(() => {
			// 	domainModel.openModel(item, editorUi.editor);
			// }).catch(	(e) => {
			// 		console.error("GraphPage.onItemsSelected", e)
			// 		mx.mxUtils.alert(mx.mxResources.get('invalidOrMissingFile') + ': ' + (e.message ? e.message : e))
			// });
		}
	}

	renderDismissibleSidebarContent(theme?: ThemeColorBG) {
				return	<Container vertical={true} style={{ height: '100%' }}>
								<GraphToolbar theme={theme}  />
								<GraphDiagram />
							</Container>
	}
  
  render() {
		const theme = ThemeColorBG.light;
		console.log("GraphPage.Render", this.state.selectedItems);
    return (
      <GraphProvider  >
				<GraphEditor ref={this.editorRef} style= {{width: "100%"}}
					onInit={this.onInit.bind(this)} 
			  	graphSettings={
				  {
					  // resources: txtRes, 
					  // styles: stylesRes,
					  collapsedIcon: collapsedIcon, 
					  expandedIcon: expandedIcon,
					  selectionStrokeWidth: 2, 
					  foldingAlignRight: true,
					  onConfigure: graph => {
							graph.setConnectable(true);
							graph.setCellsCloneable(false);
							graph.swimlaneNesting = false;
							graph.dropEnabled = true;
							//?? graph.connectionArrowsEnabled = true; 
							graph.setAllowDanglingEdges(false);
					  }
					}}
			  >


				<Container vertical={true} style={{ height: '100%' }}>
					{/* <MxMenubar />
					<MxToolbar /> */}
					<Container style={{ height: '100%' }}>
					<ResizableSection  minSize={400} defaultSize={900} >
					
						<DismissibleSidebar theme={theme} content={this.renderDismissibleSidebarContent(theme)}>
              <SidebarSection align="start">
							<SidebarItem icon="insert_drive_file" selected defaultSize={200} resizable minSize={150}>
									<Toolbar theme={theme}>
											<ToolbarRow>
												<ToolbarSection align="start">
													<ToolbarLabel>Browse</ToolbarLabel>
												</ToolbarSection>
												<ToolbarSection align="end">
													<ToolbarButton icon="autorenew" onClick={() => {this.designerService!.refresh() }} />
													<ToolbarButton icon="add" onClick={() => {this.designerService!.newDocument() }} /> 
													<ToolbarButton icon="filter_none" onClick={() => this.setState({ collapseAll: true})} /> 
												</ToolbarSection>
											</ToolbarRow>
									</Toolbar> 
									<ApolloConsumer>
        						{client => (
											
											
											
												<TreeView theme={theme}  collapseAll={this.state && this.state.collapseAll } selectedItems={this.state.selectedItems} onItemSelected={(item: any) => this.onItemSelected(item) } />
												
											
											
											)}
      						</ApolloConsumer>
								</SidebarItem>
                <SidebarItem icon="create" defaultSize={80}>
									<Toolbar theme={theme}>
                      <ToolbarRow>
                        <ToolbarSection align="start">
                          <ToolbarLabel style={{fontWeight: "unset"}} >Stencil</ToolbarLabel>
                      </ToolbarSection>
                    </ToolbarRow>
                  </Toolbar>
									<GraphSidebar >
										<GraphSidebarPanel>
												<GraphStencil image={tableIcon} height={48} width={48} domainClassName="class" />
												<GraphStencil image={columnIcon} height={48} width={48} domainClassName="property" />
										</GraphSidebarPanel>
								</GraphSidebar>
                </SidebarItem>
                
              </SidebarSection>
              

            </DismissibleSidebar>

							
						</ResizableSection>
						{/* <Section size={50}>
						
						</Section>
						 */}
							
						<Section minSize={100} >
							<GraphPropertyEditor theme={theme} />
						</Section>
			  
					
					

						
					</Container>
  			</Container>
		  
				</GraphEditor>
  		</GraphProvider>
    );
  }
}

export default Page; 