import React, {Component} from 'react';
import { Link } from 'react-router-dom'
import { withRouter, RouteComponentProps } from 'react-router'

import {
    Drawer,
    DrawerHeader,
    DrawerContent,
    DrawerTitle,
    DrawerSubtitle,
    DrawerAppContent
  } from '@rmwc/drawer';
  import '@material/drawer/dist/mdc.drawer.css';
  
  import {
    List,
    ListItem,
    ListItemGraphic,
    ListItemPrimaryText
  } from '@rmwc/list';
  import '@material/list/dist/mdc.list.css';
  
  interface IMyTopMenuProps {
    opened: boolean;
    onClose: () => void;
  }

  type PropsType = IMyTopMenuProps & RouteComponentProps;
  
  class MyTopMenu extends Component<PropsType> { 
    render() {
        return (
            <Drawer dismissible={true} open={this.props.opened} onClose={() => this.props.onClose() }>
                {/* <DrawerHeader>
                    <DrawerTitle>DrawerHeader</DrawerTitle>
                    <DrawerSubtitle>Subtitle</DrawerSubtitle>
                </DrawerHeader> */}
                <DrawerContent>
                    <List>
                        <ListItem {...{ tag: Link, to: "/repository", onClick: () => this.props.onClose() } }>
                            <ListItemGraphic icon="library_books"/>
                            Repository
                        </ListItem>
                        <ListItem {...{ tag: Link, to: "/designer", onClick: () => this.props.onClose() } }>
                            <ListItemGraphic icon="create"/>
                            Designer
                        </ListItem>
                        <ListItem {...{ tag: Link, to: "/designer2", onClick: () => this.props.onClose() } }>
                            <ListItemGraphic icon="create"/>
                            Designer
                        </ListItem>
                        <ListItem {...{ tag: Link, to: "/addons", onClick: () => this.props.onClose() } }>
                            <ListItemGraphic icon="extension"/>
                            Extensions
                        </ListItem> 
                    </List>
                </DrawerContent>
            </Drawer>
        )
    }
  };
    
  export default withRouter<PropsType>(MyTopMenu);