import React from 'react';
import ReactDOM from 'react-dom';
import gql from 'graphql-tag';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { Query, ApolloProvider } from 'react-apollo';
import {resolvers, typeDefs} from './data/resolvers';
import { BrowserRouter} from 'react-router-dom'
import '@material/theme/dist/mdc.theme.css';
import {Workbench} from 'my-dsl-core';
import App from './App';
import Login from './pages/LoginPage';
import * as serviceWorker from './serviceWorker';
import 'my-dsl-core/dist/index.css';
import './index.css';

const cache = new InMemoryCache();
const servers = [
  { name: "Local", uri: "http://localhost:4002" },
  { name: "Cloud", uri: "https://server.boehmem.now.sh" },
  { name: "emibeee", uri: "https://graphql.emibeee.com" }
];

function getUri() {
  const uri = localStorage.getItem('serverUri');
  return uri ? uri : servers[0].uri;
}

const httpLink = createHttpLink({
    uri: () => getUri(), // 'https://server.boehmem.now.sh', //'http://localhost:4000/'
  });
  
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? token : "",
      }
    } 
  }); 

const client = new ApolloClient({
    cache,
    link: authLink.concat(httpLink), 
    resolvers,
    typeDefs,
  });
  
  cache.writeData({
    data: {
      isLoggedIn: !!localStorage.getItem('token'),
      // "langs({})": { 
      //   __typename: "LangConnection",
      
      //   cursor: 0,
      //   hasMore: true,
      //   langs: []
      //  }
    },
  });

const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

ReactDOM.render(
    <BrowserRouter>
        <ApolloProvider client={client}>
            <Query<{ isLoggedIn: boolean },{}> query={IS_LOGGED_IN}>
            {({ data }) => (data!.isLoggedIn ? 
              <Workbench apolloClient={client}><App /></Workbench> : 
              <Login servers={servers} setUri={(uri) => localStorage.setItem('serverUri', uri) } />)}
            </Query>
        </ApolloProvider>
    </BrowserRouter>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
