import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route } from 'react-router-dom'

import TopAppBar from './components/TopAppBar';
import StartPage from './components/StartPage';
import RepositoryPage from './components/RepositoryPage';
import DesignerPage from './pages/designer';
import DesignerPage2 from './pages/designer2';
import {Loading} from './components/Loading';


import logo from './logo.svg';
import './App.css';

class App extends Component { 
  render() {
    return (
      
        <div className="App">
          <TopAppBar />
          <div style={{flex: "1"}} >
          
            <Suspense fallback={<Loading />} >
            
              <Switch >
                <Route exact path='/' component={StartPage}/>
                <Route exact path='/repository' component={RepositoryPage}/>
                <Route exact path='/designer' component={DesignerPage}/>
                <Route exact path='/designer2' component={DesignerPage2}/>
              </Switch>
            </Suspense>
          </div>
         
        </div>
      );
  }
} 

export default App; 

