import React, { Component, lazy } from 'react';

// import {require} from 'webpack';


//  const LoadableComponent: React.SFC<IDslEditorDispatcherProps> = (props) => {
//     return Loadable({
//       loader: () => import(props.item.dsl),
//       modules: [props.item.dsl],
//       webpack: () => [require.resolveWeak(props.item.dsl)],
//       loading() {
//         return <div>Loading...</div>
//       }
//     });
//  }

const LoadableEnumEditor = (
  lazy(() => import('./editors/EnumEditor')
  ))
  const LoadableTypeEditor = (
    lazy(() => import('./editors/TypeEditor')
    ))





// const LoadableComponent = MyLoadable(opts) {
//   return Loadable(Object.assign({
//     loading: Loading,
//     delay: 200,
//     timeout: 10000,
//   }, opts));
// };

interface IDslEditorDispatcherProps {
  item?: any;
}

export default class DslEditorDispatcher extends Component<IDslEditorDispatcherProps> {
  render() {
    if (!this.props.item || !this.props.item.dsl) {
      return null;
    }
    else if (this.props.item.dsl === 'enum')
      return <LoadableEnumEditor />;
    else if (this.props.item.dsl === 'type')
      return <LoadableTypeEditor />;
    else
      return null; 
      // <LoadableComponent item={this.props.item}/>
  }
}