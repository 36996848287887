import React, { Component } from 'react';
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import {Button} from '@rmwc/button';
import {TextField} from '@rmwc/textfield';
import {Typography} from '@rmwc/typography';
import {Select} from '@rmwc/select';
import { CircularProgress } from '@rmwc/circular-progress';

import { withThemeBG, ThemeColorBG, ThemeColor, withThemeColor } from 'my-dsl-core';

import '@rmwc/circular-progress/circular-progress.css';
import '@material/button/dist/mdc.button.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/select/dist/mdc.select.css';


export interface BackendServer {
  name: string;
  uri: string;
}

interface LoginFormProps {
  servers: BackendServer[];
  login: (userId: string, server?: string) => void;
  error?: string;
  defaultServer?: string;
  loading?: boolean;
}

interface LoginFormState {
  userId: string;
  server?: string; 
}

export class LoginForm extends Component<LoginFormProps,LoginFormState> {
  
  constructor(props: LoginFormProps) {
    super(props);

    let server: string | undefined = undefined;
    if (props.servers && props.servers.length > 1) {
      if (props.defaultServer) {
        const entry = props.servers.find( s => s.name === props.defaultServer);
        if (entry) server = entry.uri;
      } 
      if (!server) {
        server = props.servers[0].uri;
      }
    }
    
    this.state = {server: server, userId: ""};
  }

  onChangeUserId = (event: any) => {
    const userId = event.target.value;
    this.setState(s => ({ userId }));
  };
  onChangeServer = (event: any) => {
    const server = event.target.value;
    this.setState(s => ({ server }));
  };

  onSubmit = (event: any) => {
    event.preventDefault();
    this.props.login(this.state.userId, this.state.server);
  };

  
  render() {
    const {loading, servers} = this.props;
    let options;
    if (this.state.server) {
      options = servers.reduce( (v: any, x) => { v[x.uri] = x.name; return v; }, {} );
    }
  
    return (
      <Container>
       
        <Heading use="headline3">myDsl</Heading>
        <StyledForm onSubmit={this.onSubmit}>
          { this.state.server && <StyledSelect label="Server" value={this.state.server} options={options} onChange={this.onChangeServer} /> }
          <StyledInput name="userId" label="User / Email" onChange={this.onChangeUserId} />
           
          <ErrorLabel use="caption">{this.props.error}</ErrorLabel> 
          <StyledButton raised disabled={loading} icon={ loading ? <CircularProgress theme="onSecondary" /> : null} >
            <StyledButtonText loading={loading}>Log in</StyledButtonText>
          </StyledButton>
          
        </StyledForm>
      </Container>
    );
  }
}

/**
 * STYLED COMPONENTS USED IN THIS FILE ARE BELOW HERE
 */

const unit = 8;

const Container = styled(withThemeBG("div", ThemeColorBG.primary))({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 1,
  paddingBottom: unit * 6,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  boxSizing: "border-box",
});

const Heading = styled(Typography)({
  margin: `${unit * 6}px 0 ${unit * 3}px`,
});

const StyledForm = styled('form')({
  width: '100%',
  maxWidth: 406,
  marginTop: unit * 5,
  padding: unit * 3.5,
  borderRadius: 3,
  boxShadow: '6px 6px 1px rgba(0, 0, 0, 0.25)',
  backgroundColor: 'white',
  boxSizing: "border-box"
});


const styledFormElement = css({ 
  width: '100%',
  boxSizing: "border-box",
  fontSize: 16,
})

const StyledInput = styled(TextField)(styledFormElement);
const StyledSelect = styled(Select)(styledFormElement, {
  marginBottom: unit * 2,
});

const ErrorLabel = styled(withThemeColor(Typography, ThemeColor.error))({
  minHeight: "1.25rem", 
  display: "block"
});

const StyledButton = styled(withThemeBG(Button, ThemeColorBG.secondary))({
  display: 'block',
  minWidth: 200,
  margin: '0 auto',
  padding: `0 ${unit * 4}px`,
  textTransform: 'uppercase',
  
});

const StyledButtonText = styled.span<{loading?: boolean}>({
  verticalAlign: -1,
  },
  props => ({
    marginRight: props.loading ? 22 : 0
  })
);
